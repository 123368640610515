/**
 * Адаптирует время для начальной даты в DatePicker.
 * @param {Date} date - Выбранная дата.
 * @returns {Date} Новая дата с установленным временем.
 */
export const adjustStartTime = date => {
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
    now.setSeconds(0);
    now.setMilliseconds(0);

    date.setSeconds(0);
    date.setMilliseconds(0);

    if (date.toDateString() === now.toDateString()) {
        date.setHours(now.getHours());
        date.setMinutes(now.getMinutes());
    } else {
        date.setHours(0);
        date.setMinutes(0);
    }
    return date;
};

/**
 * Адаптирует время для конечной даты в DatePicker.
 * @param {Date} date - Выбранная дата.
 * @returns {Date} Новая дата с установленным временем 23:24.
 */
export const adjustEndTime = date => {
    date.setHours(23);
    date.setMinutes(24);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
};
