// TrainersList.js
import React, { useEffect } from "react";
import { Collapse, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { useTrainers } from "../../../context/TrainersContext";
import { formatDate } from "../../../utils/FormatDate";
import TrainerPhoto from "../TimeSlot/TrainerFoto/TrainerFoto";
import CompletedTrainings from "../TimeSlot/CompletedTrainings/CompletedTrainings";
import Rating from "../TimeSlot/Rating/Rating";
import { useAuth } from "../../../context/AuthContext";
import { handleBooking } from "../../../utils/bookingUtils";

import "./TrainersList.css";

const TimeSlotItem = ({ slot, token }) => {
    const { user } = useAuth();

    return (
        <div className="time-slot-item-container">
            <div className="time-slot-item-date">
                {formatDate(slot.time_start)}
            </div>
            <Button
                type="primary"
                onClick={() => handleBooking(token, slot.schedule_id)}
                icon={<CheckCircleOutlined />}
                className="time-slot-item-btn-text"
                disabled={user ? !user.is_email_confirmed : true}
                title={
                    user
                        ? user.is_email_confirmed
                            ? "Стань лучше!"
                            : "Подтверди свой емейл"
                        : "Зарегистрируйся и подтверди e-mail"
                }
            >
                Забронировать
            </Button>
        </div>
    );
};

const TrainersList = () => {
    const { trainers, loadTrainers } = useTrainers();
    const { user } = useAuth();

    useEffect(() => {
        loadTrainers();
        // if (!user) {
        //     const user = localStorage.getItem('user');
        // }
    }, [loadTrainers, user]);

    const items = Object.entries(trainers).map(([trainerId, slots]) => ({
        key: trainerId,
        label: (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <TrainerPhoto
                    trainerId={trainerId}
                    token={user?.access_token}
                />
                <CompletedTrainings
                    trainerId={trainerId}
                    token={user?.access_token}
                />
                <Rating trainerId={trainerId} />
            </div>
        ),
        children: slots.map((slot, index) => (
            <TimeSlotItem key={index} slot={slot} token={user?.access_token} />
        )),
    }));

    return <Collapse accordion items={items} />;
};

export default TrainersList;
