// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

import { getUserTimezone } from "../utils/timezone";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    useEffect(() => {
        const userData = localStorage.getItem("user");
        if (userData) {
            setUser(JSON.parse(userData));
            setIsAuthenticated(true);
        }
    }, []);

    const login = async (email, password, re_captcha_token) => {
        const time_zone = getUserTimezone();

        try {
            const response = await fetch("/api/v11/login", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email.toLowerCase(),
                    password,
                    time_zone,
                    re_captcha_token,
                }),
            });
            const data = await response.json();

            if (response.ok) {
                const tokenData = data.access_token;
                const isTrainer = data.is_trainer;
                const email = data.email;
                const is_email_confirmed = data.is_email_confirmed;
                const nickname = data.nickname;
                const user = {
                    ...tokenData,
                    isTrainer,
                    email,
                    is_email_confirmed,
                    nickname,
                };
                setUser(user);
                setIsAuthenticated(true);
                localStorage.setItem("user", JSON.stringify(user));
                return user;
            } else {
                setRefreshReCaptcha(!refreshReCaptcha);
                throw new Error(data.message || "Unable to login");
            }
        } catch (error) {
            console.error("Login error:", error);
            setRefreshReCaptcha(!refreshReCaptcha);
            throw error;
        }
    };

    const register = async (email, password, isTrainer, re_captcha_token) => {
        const is_trainer = isTrainer;
        const time_zone = getUserTimezone();
        try {
            const response = await fetch("/api/v11/register", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email.toLowerCase(),
                    password,
                    is_trainer,
                    time_zone,
                    re_captcha_token,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                const tokenData = data.access_token;
                const isTrainer = data.is_trainer;
                const email = data.email;
                const is_email_confirmed = data.is_email_confirmed;
                const nickname = data.nickname;
                const user = {
                    ...tokenData,
                    isTrainer,
                    email,
                    is_email_confirmed,
                    nickname,
                };
                setUser(user);
                setIsAuthenticated(true);
                localStorage.setItem("user", JSON.stringify(user));
                return user;
            } else {
                setRefreshReCaptcha(!refreshReCaptcha);
                throw new Error(data.detail.error || "Unable to register");
            }
        } catch (error) {
            console.error("Registration error:", error);
            setRefreshReCaptcha(!refreshReCaptcha);
            throw error;
        }
    };

    const confirm_email = async token => {
        try {
            const response = await fetch(
                "/api/v11/user/verify_email/update_user",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ token }),
                },
            );
            const data = await response.json();
            if (response.ok) {
                const tokenData = data.access_token;
                const isTrainer = data.is_trainer;
                const email = data.email;
                const is_email_confirmed = data.is_email_confirmed;
                const nickname = data.nickname;
                const user = {
                    ...tokenData,
                    isTrainer,
                    email,
                    is_email_confirmed,
                    nickname,
                };
                setUser(user);
                setIsAuthenticated(true);
                localStorage.setItem("user", JSON.stringify(user));
                return user;
            } else {
                throw new Error(data.message || "Unable to login");
            }
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    };

    const oauth_callback = async data => {
        const tokenData = data.access_token;
        const isTrainer = data.is_trainer;
        const email = data.email;
        const is_email_confirmed = data.is_email_confirmed;
        const nickname = data.nickname;
        const user = {
            ...tokenData,
            isTrainer,
            email,
            is_email_confirmed,
            nickname,
        };
        setUser(user);
        setIsAuthenticated(true);
        localStorage.setItem("user", JSON.stringify(user));
        return user;
    };

    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem("user");
    };

    const updateUser = updatedUser => {
        setUser(updatedUser);
        localStorage.setItem("user", JSON.stringify(updatedUser));
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                login,
                register,
                logout,
                confirm_email,
                refreshReCaptcha,
                setRefreshReCaptcha,
                updateUser,
                oauth_callback,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
