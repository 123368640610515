import React from "react";

import AddTimeSlotForm from "./addTimeSlotForm/AddTimeSlotForm";
import TrainerSchedule from "./trainerSchedule/TrainerSchedule";

function TimeSlotAndSchedule() {
    return (
        <div>
            <h2>Твои временные слоты</h2>
            <AddTimeSlotForm onAddSlot={() => window.location.reload()} />
            <TrainerSchedule />
        </div>
    );
}

export default TimeSlotAndSchedule;
