import React from "react";

import { useAuth } from "../../../context/AuthContext";
import TrainerPhoto from "./TrainerFoto/TrainerFoto";
import TrainerAliasInput from "./trainerAliasInput/TrainerAliasInput";

import "./TrainerProfile.css";

function TrainerProfile() {
    const { user } = useAuth();

    if (!user) {
        return <div>Loading or not logged in...</div>;
    }

    return (
        <>
            <h1>Профиль</h1>
            <div className="trainer-profile-container">
                <div className="trainer-profile-foto-and-email">
                    <div className="trainer-profile-foto">
                        <TrainerPhoto />
                    </div>
                    <p>E-mail: {user.email}</p>
                </div>
                <div className="trainer-profile-alias-input">
                    <TrainerAliasInput />
                </div>
            </div>
        </>
    );
}

export default TrainerProfile;
