// TrainerContext.js
import React, { createContext, useContext, useState } from "react";

const TrainerContext = createContext({
    trainers: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTrainerData: () => {},
});

export const useTrainer = () => useContext(TrainerContext);

export const TrainerProvider = ({ children }) => {
    const [trainers, setTrainers] = useState({});

    const setTrainerData = (trainerId, data) => {
        setTrainers(prev => ({ ...prev, [trainerId]: data }));
    };

    return (
        <TrainerContext.Provider value={{ trainers, setTrainerData }}>
            {children}
        </TrainerContext.Provider>
    );
};
