/**
 * Получает текущую временную зону пользователя и возвращает её в виде JSON строки.
 * Эта функция использует международный API форматирования даты для определения временной зоны
 * и возвращает её в формате строки JSON.
 * @returns {string} Временная зона пользователя в формате JSON (например, "{\"timeZone\":\"Europe/London\"}").
 */
export const getUserTimezone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
};
