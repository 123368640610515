// Video.js
import React, { forwardRef } from "react";
import "./Video.css";

const Video = forwardRef(
    ({ stream, className, placeholderImage, muted = false }, ref) => {
        // useEffect(() => {
        //     if (ref.current) {
        //         const videoElement = ref.current;
        //         if (!isVideoEnabled) {
        //             const canvas = document.createElement("canvas");
        //             const ctx = canvas.getContext("2d");
        //             const image = new Image();
        //             image.src = placeholderImage;
        //             image.onload = () => {
        //                 canvas.width = videoElement.videoWidth;
        //                 canvas.height = videoElement.videoHeight;
        //                 ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        //                 const stream = canvas.captureStream(30);
        //                 videoElement.srcObject = stream;
        //             };
        //         } else if (stream) {
        //             videoElement.srcObject = stream;
        //         }
        //     }
        // }, [isVideoEnabled, placeholderImage, ref, stream]);

        return (
            <video
                playsInline
                muted={muted}
                ref={ref}
                poster={placeholderImage}
                autoPlay
                className={className}
            />
        );
    },
);

Video.displayName = "Video";

export default Video;
