import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";

import { AuthProvider, useAuth } from "./context/AuthContext";
import { TrainerProvider } from "./context/TrainerContext";
import TrainerDashboard from "./components/trainer/TrainerDashboard";
import StudentDashboard from "./components/student/StudentDashboard";
import HomePage from "./components/homePage/HomePage";
import themeConfig from "./configs/themeConfig";
import { TrainersProvider } from "./context/TrainersContext";
import ConfirmEmail from "./components/auth/confirmEmail/confirmEmailComponent/ConfirmEmail";
import OauthCallback from "./components/auth/oauthCallback/OauthCalback";
import VideoChat from "./components/videoChat/VideoChat";

import "./App.css";

function AppWrapper() {
    return (
        <AuthProvider>
            <Router>
                <ConfigProvider theme={themeConfig}>
                    <TrainersProvider>
                        <App />
                    </TrainersProvider>
                </ConfigProvider>
            </Router>
        </AuthProvider>
    );
}

function App() {
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            document.body.style.zoom = "reset";
            setTimeout(() => {
                document.body.style.zoom = "";
                window.scrollTo(0, 0);
            }, 50);
        }
    }, [isAuthenticated]);

    return (
        <div>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/register" element={<Navigate replace to="/" />} />
                <Route
                    path="/trainer-dashboard"
                    element={
                        <TrainerProvider>
                            <TrainerDashboard />
                        </TrainerProvider>
                    }
                />
                <Route
                    path="/student-dashboard"
                    element={
                        <TrainerProvider>
                            <StudentDashboard />
                        </TrainerProvider>
                    }
                />
                <Route path="/video-chat" element={<VideoChat />} />
                <Route path="/verify/:token" element={<ConfirmEmail />} />{" "}
                <Route path="/oauth/callback" element={<OauthCallback />} />
                {/* Маршрут для подтверждения почты */}
            </Routes>
        </div>
    );
}

export default AppWrapper;
