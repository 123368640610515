import React from "react";
import { Avatar } from "antd";

import "./AvatarBeatFade.css"; // Импортируем стили

const CustomIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="616.000000pt"
        height="645.000000pt"
        viewBox="0 0 616.000000 645.000000"
        fill="currentColor"
    >
        <g
            transform="translate(0.000000,645.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
        >
            <path
                d="M1026 6424 c-171 -31 -383 -123 -518 -224 -249 -188 -417 -455 -485
                -770 -17 -81 -18 -193 -18 -2210 0 -1983 1 -2130 17 -2206 56 -257 168 -466
                347 -645 185 -186 399 -301 654 -350 94 -18 173 -19 2066 -19 1728 0 1979 2
                2050 15 506 96 912 499 1006 997 14 74 15 327 13 2243 l-3 2160 -27 95 c-64
                227 -162 396 -325 563 -175 179 -392 298 -630 346 -100 21 -120 21 -2085 20
                -1671 -1 -1995 -3 -2062 -15z m1863 -858 c8 -10 10 -635 9 -2367 l-3 -2354
                -25 -3 c-21 -2 -67 39 -275 248 l-250 250 -1 1069 -2 1069 -22 66 c-48 146
                -172 262 -325 302 -95 26 -196 10 -307 -46 l-59 -30 -224 0 c-265 0 -255 -4
                -255 116 l0 72 333 318 c183 176 454 434 602 574 148 140 379 362 514 492 135
                131 253 238 262 238 9 0 22 -6 28 -14z m834 -364 c216 -208 475 -457 577 -552
                102 -96 308 -291 458 -433 l272 -259 0 -82 c0 -50 -5 -87 -12 -94 -9 -9 -76
                -12 -239 -12 l-226 0 -65 34 c-132 68 -269 70 -406 5 -119 -57 -217 -187 -242
                -318 -6 -34 -10 -443 -10 -1106 l0 -1052 -245 -244 c-135 -134 -254 -245 -264
                -247 -10 -2 -23 3 -29 11 -9 11 -12 582 -10 2361 3 2073 5 2348 18 2356 8 5
                19 10 23 10 4 0 184 -170 400 -378z m-2141 -1990 c15 -32 44 -77 65 -101 42
                -50 139 -116 183 -126 17 -4 32 -10 35 -14 4 -7 -596 -613 -627 -632 -24 -15
                -57 -9 -72 13 -14 19 -16 79 -16 445 0 250 4 432 10 447 l10 26 192 -1 193 0
                27 -57z m3443 47 c4 -6 8 -210 9 -453 1 -473 1 -476 -48 -476 -13 0 -56 34
                -111 87 -283 271 -565 549 -565 555 0 5 8 8 18 8 31 0 136 58 186 101 30 26
                58 66 82 115 л37 74 193 0 c127 0 195 -4 199 -11z m-2907 -681 c17 -17 17
                -929 0 -946 -33 -33 -57 -14 -299 220 -208 202 -240 236 -237 260 2 20 61 82
                238 252 239 231 264 248 298 214z m2159 -130 c315 -303 323 -312 323 -345 0
                -23 -9 -38 -32 -58 -18 -15 -124 -117 -235 -226 -210 -206 -230 -220 -267
                -183 -14 14 -16 70 -16 466 0 406 2 453 17 470 34 37 57 24 210 -124z"
            />
        </g>
    </svg>
);

function FMAvatarBeatFade({ bgColor }) {
    return (
        <div className="centered-container">
            <Avatar
                size={64}
                style={{
                    backgroundColor: bgColor,
                    border: "none",
                    borderRadius: "20%",
                }}
                icon={<CustomIcon />}
                className="fade-beat"
            />
            <br />
            <div>Loading...</div>
        </div>
    );
}

export default FMAvatarBeatFade;
