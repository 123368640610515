// /src/components/trainer/AddTimeForm.js
import React, { useState } from "react";
import { DatePicker, message, Form, Button } from "antd";
import moment from "moment";

import { useAuth } from "../../../../context/AuthContext";
import { getUserTimezone } from "../../../../utils/timezone";
import { useSchedule } from "../../../../context/ScheduleContext";

import "./AddTimeSlotForm.css";

function AddTimeSlotForm() {
    const [startDate, setStartDate] = useState(moment());
    const [error, setError] = useState("");
    const { user } = useAuth();
    const { fetchTimeSlots } = useSchedule();

    const handleSubmit = async () => {
        const timeZone = getUserTimezone();
        try {
            const response = await fetch("/api/v11/schedule/create", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    time_start: startDate.toISOString(), // Преобразуем дату в строку в формате ISO
                    time_zone: { time_zone: timeZone }, // Отправляем временную зону как строку
                }),
            });
            if (!response.ok) {
                const errorData = await response.json(); // Получаем JSON с ошибкой, если что-то пошло не так
                throw new Error(errorData.message || "Failed to add time slot");
            }
            await fetchTimeSlots(); //onAddSlot(await response.json()); // Обрабатываем успешное добавление
            setError(""); // Очищаем ошибки, если все прошло хорошо
        } catch (error) {
            console.error("Error adding time slot:", error);
            setError(error.message);
            message.error(error.message);
        }
    };

    return (
        <Form onFinish={handleSubmit} className="add-time-slot-form">
            {error && (
                <div className="add-time-slot-form-error-message">{error}</div>
            )}
            <Form.Item>
                <DatePicker
                    showTime={{
                        format: "HH:mm",
                        minuteStep: 15,
                        popupStyle: { fontSize: 12 },
                    }}
                    format="D MMMM, YYYY HH:mm"
                    onChange={date => setStartDate(date)}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Add Time Slot
                </Button>
            </Form.Item>
        </Form>
    );
}

export default AddTimeSlotForm;
