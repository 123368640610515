// src/components/trainer/TrainerDashboard.js
import React from "react";

import { ScheduleProvider } from "../../context/ScheduleContext";
import BookingList from "./bookingList/BookingList";
import TrainerProfile from "./TrainerProfile/TrainerProfile";
import MenuDashboard from "../menu/menuDashboard/MenuDashboard";
import Header from "../homePage/header/Header";
import TimeSlotAndSchedule from "./timeSlotAndSchedule/TimeSlotAndSchedule";

import "./TrainerDashboard.css";

function TrainerDashboard() {
    return (
        <ScheduleProvider>
            <Header />
            <MenuDashboard />
            <div className="trainer-dashboard-profile">
                <TrainerProfile />
            </div>
            <div className="trainer-dashboard-container">
                <div className="trainer-dashboard-timeslotschedule">
                    <TimeSlotAndSchedule />
                </div>
                <div className="trainer-dashboard-bookinglist">
                    <BookingList />
                </div>
            </div>
        </ScheduleProvider>
    );
}

export default TrainerDashboard;
