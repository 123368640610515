import React from "react";

import "./MenuIcon.css";

const CrosshairIcon = () => (
    <svg
        className="crosshair-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
        <line
            x1="12"
            y1="2"
            x2="12"
            y2="6"
            stroke="currentColor"
            strokeWidth="2"
        />
        <line
            x1="12"
            y1="18"
            x2="12"
            y2="22"
            stroke="currentColor"
            strokeWidth="2"
        />
        <line
            x1="2"
            y1="12"
            x2="6"
            y2="12"
            stroke="currentColor"
            strokeWidth="2"
        />
        <line
            x1="18"
            y1="12"
            x2="22"
            y2="12"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export default CrosshairIcon;
