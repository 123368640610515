// src/student/StudentDashboard.js
import React, { useState } from "react";
import { Collapse, Button } from "antd";

import { useAuth } from "../../context/AuthContext";
import { useTrainers } from "../../context/TrainersContext";
import SearchTrainersForm from "./studentTrainersForm/SearchTrainersForm"; // Убедитесь, что путь до компонента корректен
import TimeSlot from "./TimeSlot/TimeSlot";
import TrainersList from "./trainersList/TrainersList";
import { handleBooking } from "../../utils/bookingUtils";
import Header from "../homePage/header/Header";
import MenuDashboard from "../menu/menuDashboard/MenuDashboard";

import "./StudentDashboard.css";

function StudentDashboard() {
    const [searchResults, setSearchResults] = useState([]);
    const [showTrainers, setShowTrainers] = useState(false);
    const [trainersLoaded, setTrainersLoaded] = useState(false); // Новое состояние для отслеживания загрузки тренеров
    const { user, isAuthenticated } = useAuth();
    const { loadTrainers } = useTrainers(); // Использование функции из контекста

    const onBook = scheduleId => {
        handleBooking(user.access_token, scheduleId);
    };

    const handleLoadTrainers = async () => {
        await loadTrainers();
        setShowTrainers(true);
        setTrainersLoaded(true); // Обновляем состояние после успешной загрузки
    };

    const panelItems = searchResults.map((slot, index) => ({
        key: index.toString(), // Использовать уникальные ключи, если возможно, для лучшей оптимизации
        label: slot.time_start,
        children: <TimeSlot slot={slot} onBook={onBook} />, // Элемент, который будет отображаться внутри каждой панели
    }));

    return (
        <>
            <Header />
            {isAuthenticated && <MenuDashboard />}
            <div className="dashboard-container">
                <div className="student-dashboard-header">
                    <h1>Зона падавана</h1>
                    <div className="header-line"></div>
                </div>
                <div className="content-container">
                    <div className="results-container">
                        <SearchTrainersForm onResults={setSearchResults} />
                        {searchResults.length > 0 ? (
                            <>
                                <h2>Результаты поиска</h2>
                                <Collapse
                                    accordion
                                    className="search-results-collapse"
                                    items={panelItems}
                                />
                            </>
                        ) : (
                            <div>Нет тренировок в этом промежутке времени.</div>
                        )}
                    </div>
                    <div className="trainers-container">
                        <div className="btn-or-text-container">
                            {!trainersLoaded ? (
                                <Button
                                    className="search-all-trainers-btn"
                                    type="primary"
                                    onClick={handleLoadTrainers}
                                >
                                    Загрузить список тренеров
                                </Button>
                            ) : (
                                <div className="found-trainers-text">
                                    <h2>Найденные активные тренеры</h2>
                                </div>
                            )}
                            {showTrainers && <TrainersList />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentDashboard;
