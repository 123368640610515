// src/config/themeConfig.js

const themeConfig = {
    token: {
        fontFamily: '"Tektur", sans-serif',
        // Другие глобальные стили, если необходимо
    },
    components: {
        Button: {
            colorPrimaryBorderHover: "red",
            colorPrimaryHover: "red",
            colorPrimary: "black",
            colorPrimaryActive: "lightgray",
            colorPrimaryTextHover: "lightgray",
        },
        Menu: {
            activeBarBorderWidth: 1,
            itemActiveBg: "red",
            itemHoverBg: "red",
            itemSelectedColor: "black",
        },
    },
};

export default themeConfig;
