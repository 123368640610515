// src/context/ScheduleContext.js
import React, { createContext, useContext, useState, useCallback } from "react";

import { formatDate } from "../utils/FormatDate";
import { useAuth } from "./AuthContext";

const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
    const { user } = useAuth();
    const [timeslots, setTimeslots] = useState([]);

    const fetchTimeSlots = useCallback(async () => {
        if (!user || !user.isTrainer) {
            return;
        }
        try {
            const timeZone = {
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }; // Assume getUserTimezoneJsonified returns this
            const response = await fetch(`/api/v11/schedule/search/token`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(timeZone),
            });
            if (!response.ok) {
                throw new Error("Failed to fetch timeslots");
            }
            const data = await response.json();
            setTimeslots(
                data.map(slot => ({
                    ...slot,
                    time: formatDate(slot.time),
                })),
            );
        } catch (error) {
            console.error("Error fetching timeslots:", error);
        }
    }, [user]);

    return (
        <ScheduleContext.Provider value={{ timeslots, fetchTimeSlots }}>
            {children}
        </ScheduleContext.Provider>
    );
};

export const useSchedule = () => useContext(ScheduleContext);
