import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Form, Input, Checkbox, Button, notification } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import { useAuth } from "../../../context/AuthContext";

import "./registration-form.css";

function RegistrationForm() {
    const { register, refreshReCaptcha, setRefreshReCaptcha } = useAuth();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [re_captcha_token, setToken] = useState("");

    const onFinish = async values => {
        try {
            const user = await register(
                values.email,
                values.password,
                values.isTrainer,
                re_captcha_token,
            );
            console.log("Registration successful:", user);
            navigate("/"); //user.isTrainer ? navigate('/trainer-dashboard') : navigate('/student-dashboard');
        } catch (error) {
            console.error("Registration failed:", error);
            notification.error({
                message: "Registration Failed",
                description:
                    error.message || "Please check your registration details.",
                placement: "topRight",
            });
            setRefreshReCaptcha(!refreshReCaptcha);
        }
    };

    const setTokenFunc = getToken => {
        setToken(getToken);
    };

    return (
        <Form
            form={form}
            name="registration_form"
            onFinish={onFinish}
            layout="vertical"
            size="large"
            initialValues={{ isTrainer: false }}
            className="registration-form"
        >
            <Form.Item
                name="email"
                rules={[
                    { type: "email", message: "Что то не так с твоим E-mail!" },
                    { required: true, message: "Введи свой E-mail!" },
                ]}
            >
                <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: "Введи свой пароль!" }]}
            >
                <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Пароль"
                />
            </Form.Item>
            <Form.Item
                name="isTrainer"
                valuePropName="checked"
                className="checkbox-item"
            >
                <Checkbox>Ты тренер?</Checkbox>
            </Form.Item>
            <Form.Item>
                <GoogleReCaptcha
                    className="google-recaptcha-custom-class"
                    onVerify={setTokenFunc}
                    action="LOGIN"
                    refreshReCaptcha={refreshReCaptcha}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="registration-form-button"
                >
                    Регистрация
                </Button>
            </Form.Item>
        </Form>
    );
}

export default RegistrationForm;
