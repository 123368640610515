// VideoControls.js
import React from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMicrophoneSlash,
    faMicrophone,
    faVideo,
    faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";

const VideoControls = ({
    isMutedSound,
    isMutedVideo,
    toggleMuteSound,
    toggleMuteVideo,
}) => (
    <div className="video-chat-video-controls">
        <Button
            className="video-chat-mute-btn"
            type="primary"
            onClick={toggleMuteSound}
            icon={
                !isMutedSound ? (
                    <FontAwesomeIcon
                        icon={faMicrophone}
                        style={{ color: "white" }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faMicrophoneSlash}
                        beat
                        style={{ color: "green" }}
                    />
                )
            }
        />
        <Button
            className="video-chat-mute-btn"
            type="primary"
            onClick={toggleMuteVideo}
            icon={
                !isMutedVideo ? (
                    <FontAwesomeIcon
                        icon={faVideo}
                        style={{ color: "white" }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faVideoSlash}
                        beat
                        style={{ color: "green" }}
                    />
                )
            }
        />
    </div>
);

export default VideoControls;
