// IncomingCall.js
import React from "react";
import { Button } from "antd";

const IncomingCall = ({ caller, callAccepted, acceptCall }) => (
    <div className="video-chat-message-box">
        <p className="video-chat-message-text">
            {caller} is {callAccepted ? "connected" : "calling you"}
        </p>
        {!callAccepted && (
            <Button type="primary" onClick={acceptCall}>
                Accept
            </Button>
        )}
    </div>
);

export default IncomingCall;
