// ./src/student/TimeSlot/Rating/Rating.js
import React from "react";
import { Rate } from "antd";

import { useTrainer } from "../../../../context/TrainerContext";
import "./Rating.css";

function Rating({ trainerId }) {
    const { trainers } = useTrainer();
    const { rating, ratingCount } =
        { rating: 3.5, ratingCount: 100 } || trainers[trainerId];

    // Преобразование рейтинга в число (если это необходимо)
    const numericRating = parseFloat(rating);

    const customRateStyle = {
        fontSize: "clamp(10px, 1.5vw, 20px)",
        color: "red",
    };

    return (
        <div className="rating-container">
            <Rate
                className="rating-stars"
                allowHalf
                disabled
                defaultValue={numericRating || 0}
                style={customRateStyle}
            />
            <div className="rating-text">
                {rating ? ` (${ratingCount} оценок)` : " No rating available"}
            </div>
        </div>
    );
}

export default Rating;
