// CallButton.js
import React from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import "./CallButton.css";

const CallButton = ({ users, yourID, callPeer }) => (
    <div className="video-chat-row">
        {Object.values(users).map(key => {
            if (key === yourID) {
                return null;
            }
            return (
                <Button
                    key={key}
                    type="primary"
                    icon={<FontAwesomeIcon icon={faPhone} />}
                    onClick={() => callPeer(key)}
                    style={{ background: "green" }}
                    className="video-chat-call-btn"
                >
                    Call
                </Button>
            );
        })}
    </div>
);

export default CallButton;
