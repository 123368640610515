// config.js
const config = {
    HOST: process.env.REACT_APP_API_HOST,
    PORT: process.env.REACT_APP_PORT,
    RE_CAPTCHA_KEY: process.env.REACT_APP_RE_CAPTCHA_KEY,
    TURN_USER: process.env.REACT_APP_TURN_USER,
    TURN_PASSWORD: process.env.REACT_APP_TURN_PASSWORD,
};

export default config;
