// components/menu/Menu.js
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer, Menu, Modal } from "antd";
import {
    HomeOutlined,
    LoginOutlined,
    SignatureOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faYandex } from "@fortawesome/free-brands-svg-icons";

import { useAuth } from "../../../context/AuthContext";
import LoginPage from "../../auth/loginForm/LoginForm";
import RegistrationForm from "../../auth/registrationForm/RegistrationForm";
import CrosshairIcon from "../../../svg/crosshairIcon/MenuIcon";

import "./MenuAuth.css";

const MenuAuth = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [current, setCurrent] = useState();
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isDashboards =
        location.pathname.includes("/trainer-dashboard") ||
        location.pathname.includes("/student-dashboard");

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const handleMenuClick = e => {
        setCurrent(e.key);
        closeDrawer(); // Закрываем Drawer после выбора пункта меню
        setModalVisible(true); // Открываем модальное окно
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const menuItems = [
        {
            label: (
                <span>
                    Войти{" "}
                    <div style={{ display: "inline", marginLeft: "30px" }}>
                        <FontAwesomeIcon icon={faGoogle} />
                        <FontAwesomeIcon
                            icon={faYandex}
                            style={{ marginLeft: "5px" }}
                        />
                    </div>
                </span>
            ),
            key: "login",
            icon: <LoginOutlined />,
        },
        {
            label: "Зарегистрироваться",
            key: "register",
            icon: <SignatureOutlined />,
        },
    ];

    if (!isAuthenticated && isDashboards) {
        menuItems.push({
            label: "Главная",
            key: "homepage",
            icon: <HomeOutlined />,
        });
    }

    return (
        <div
            className="menu-auth"
            style={{ position: "absolute", top: 0, right: 0, padding: "10px" }}
        >
            <Button
                type="primary"
                onClick={showDrawer}
                icon={<CrosshairIcon />}
            />
            <Drawer
                className="menu-auth-drawer"
                title="Меню"
                placement="right"
                onClose={closeDrawer}
                open={drawerVisible}
                style={{ opacity: 0.9 }}
            >
                <Menu
                    onClick={handleMenuClick}
                    selectedKeys={[current]}
                    items={menuItems} // Используем свойство items для определения элементов меню
                />
            </Drawer>
            <Modal
                title={current === "login" ? "Войти" : "Зарегистрироваться"}
                open={modalVisible}
                onCancel={closeModal}
                footer={null}
            >
                {current === "homepage" ? (
                    navigate("/")
                ) : current === "login" ? (
                    <LoginPage />
                ) : (
                    <RegistrationForm />
                )}
            </Modal>
        </div>
    );
};

export default MenuAuth;
