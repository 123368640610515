import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

import { useAuth } from "../../../../context/AuthContext";

import "./ConfirmEmail.css";

function ConfirmEmail() {
    const navigate = useNavigate();
    const { token } = useParams();
    const { confirm_email } = useAuth();

    useEffect(() => {
        try {
            const user = confirm_email(token);
            console.log("Login successful:", user);
            user.isTrainer
                ? navigate("/trainer-dashboard")
                : navigate("/student-dashboard");
        } catch (error) {
            console.error("Login failed:", error);
            notification.error({
                message: "Login Failed",
                description: error.message || "Login failed, please try again.",
                placement: "topRight",
            });
        }
    });

    return <div>Verifying your email...</div>;
}

export default ConfirmEmail;
