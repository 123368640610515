import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";

function OauthCallback() {
    const navigate = useNavigate();
    const location = useLocation();
    const { oauth_callback } = useAuth();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const dataString = params.get("data");
        let data;

        data = JSON.parse(decodeURIComponent(dataString));

        oauth_callback(data)
            .then(() => {
                // Перенаправление на нужную страницу после успешного логина
                navigate("/");
            })
            .catch(error => {
                console.error("OAuth callback error:", error);
            });
    }, [location, navigate, oauth_callback]);

    return <div>Загрузка...</div>;
}

export default OauthCallback;
