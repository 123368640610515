import React from "react";
import { Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { useAuth } from "../../../context/AuthContext";
import TrainerPhoto from "./TrainerFoto/TrainerFoto";
import CompletedTrainings from "./CompletedTrainings/CompletedTrainings";
import Rating from "./Rating/Rating";

import "./TimeSlot.css";

function TimeSlot({ slot, onBook }) {
    const { user } = useAuth();
    const isExpanded = true; // Всегда раскрыт для упрощения

    return (
        <>
            {isExpanded && (
                <div className="time-slot-details">
                    <div className="info-container">
                        <div className="trainer-photo-container">
                            <TrainerPhoto trainerId={slot.trainer_id} />
                        </div>
                        <div className="completed-trainings">
                            <CompletedTrainings trainerId={slot.trainer_id} />
                        </div>
                        <div className="rating">
                            <Rating trainerId={slot.trainer_id} />
                        </div>
                    </div>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            onBook(slot.schedule_id);
                        }}
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        className="time-slot-button"
                        disabled={user ? !user.is_email_confirmed : true}
                        title={
                            user
                                ? user.is_email_confirmed
                                    ? "Стань лучше!"
                                    : "Подтверди свой емейл"
                                : "Зарегистрируйся и подтверди e-mail"
                        }
                    >
                        Бронь
                    </Button>
                </div>
            )}
        </>
    );
}

export default TimeSlot;
