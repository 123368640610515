import React from "react";
import { DatePicker, Form, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// import { useAuth } from '../../../context/AuthContext';
import { adjustStartTime, adjustEndTime } from "../../../utils/TimeUtils";
import { formatDate } from "../../../utils/FormatDate";

import "./SearchTrainersForm.css";

function SearchTrainersForm({ onResults }) {
    const [form] = Form.useForm();
    // const { user } = useAuth();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleSubmit = async values => {
        const { startTime, endTime, timeZone } = values;
        const adjustedStart = adjustStartTime(new Date(startTime));
        const adjustedEnd = adjustEndTime(new Date(endTime));

        try {
            const response = await fetch("/api/v11/schedule/search/time", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // 'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify({
                    time_start: adjustedStart,
                    time_finish: adjustedEnd,
                    time_zone: { time_zone: timeZone },
                }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                    errorData.message || "Failed to search for trainers",
                );
            }
            const data = await response.json();
            const formattedData = data.map(slot => ({
                ...slot,
                time_start: formatDate(new Date(slot.time_start)), // Применение вашей функции форматирования
            }));
            const sortedData = formattedData.sort(
                (a, b) => new Date(a.time_start) - new Date(b.time_start),
            );
            onResults(sortedData);
        } catch (error) {
            console.error("Error searching for trainers:", error);
        }
    };

    return (
        <Form
            className="search-trainers-form"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                timeZone: timeZone, // Установка начального значения для поля timeZone
            }}
        >
            <h2>Найди тренера по времени</h2>
            <Form.Item
                name="startTime"
                label="Начальное время:"
                rules={[{ required: true, message: "Выбери начальное время!" }]}
            >
                <DatePicker placeholder="Выбери время" />
            </Form.Item>
            <Form.Item
                name="endTime"
                label="Конечное время:"
                rules={[{ required: true, message: "Выбери конечное время!" }]}
            >
                <DatePicker placeholder="Выбери время" />
            </Form.Item>
            <Form.Item
                name="timeZone"
                label="Твоя временная зона:"
                // rules={[{ required: true, message: 'Please input your time zone!' }]}
            >
                <Input className="search-trainers-form-timezone-input" />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    // className={user.is_email_confirmed ? 'search-trainer-form-btn-active' : 'search-trainer-form-btn-passive'}
                    // disabled={!user.is_email_confirmed}
                >
                    Найти
                </Button>
            </Form.Item>
        </Form>
    );
}

export default SearchTrainersForm;
