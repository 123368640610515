import React, { useEffect, useState, useRef } from "react";
import Peer from "simple-peer";
import process from "process";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "../../context/AuthContext";
import config from "../../configs/config";
import Video from "./video/Video";
import VideoControls from "./videoControls/VideoControls";
import CallButton from "./callButton/CallButton";
import IncomingCall from "./incomingCall/IncomingCall";
import VideoLoginModal from "./videoLoginModal/VideoLoginModal";
import FMAvatarBeatFade from "../avatars/avatarBeatFade/AvatarBeatFade";

import "./VideoChat.css";

window.process = process;

function VideoChat() {
    const { user } = useAuth();
    const [yourID, setYourID] = useState();
    const [users, setUsers] = useState([]);
    const [stream, setStream] = useState(null);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState("");
    const [callerSignal, setCallerSignal] = useState();
    const [callAccepted, setCallAccepted] = useState(false);
    const [flag, setUsersFlag] = useState(false);
    const [isMutedSound, setIsMutedSound] = useState(false);
    const [isMutedVideo, setIsMutedSVideo] = useState(false);
    const [isInCall, setIsInCall] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const prevUsers = useRef([]);

    const userVideo = useRef(null);
    const partnerVideo = useRef(null);
    const socket = useRef(null);
    const peerRef = useRef(null);
    const placeholderImage = "images/photo_2024-06-10_12-18-27.jpg";

    useEffect(() => {
        let timer;
        if (!user || !user.access_token) {
            console.error("User or access token is not available");

            // Set a timer to show login modal after 5 seconds if not authenticated
            timer = setTimeout(() => {
                setShowLoading(false);
                setShowLoginModal(true);
            }, 5000);
        } else {
            setShowLoading(false);
        }

        // Clean up the timer if user becomes authenticated or component unmounts
        return () => clearTimeout(timer);
    }, [user]);

    useEffect(() => {
        if (user && user.access_token) {
            socket.current = new WebSocket(
                `wss://fragmentor.website/api/v11/ws?token=${encodeURIComponent(
                    user.access_token,
                )}`,
            );

            socket.current.onopen = () => {
                console.log("WebSocket connection established");
                if (socket.current.readyState === WebSocket.OPEN) {
                    socket.current.send(
                        JSON.stringify({ type: "setID", id: yourID }),
                    );
                }
            };

            socket.current.onmessage = message => {
                const data = JSON.parse(message.data);
                switch (data.type) {
                    case "yourID": {
                        setYourID(data.yourID);
                        prevUsers.current = yourID;
                        console.log(yourID);
                        break;
                    }
                    case "allUsers": {
                        if (data.users !== prevUsers.current) {
                            setUsersFlag(!flag);
                            prevUsers.current = data.users;
                        }
                        setUsers(data.users);
                        console.log(
                            `принят список пользователей ${data.users}, flag = ${flag}, ${prevUsers.current}`,
                        );
                        break;
                    }
                    case "hey": {
                        setReceivingCall(true);
                        setCaller(data.from);
                        setCallerSignal(data.signal);
                        break;
                    }
                    case "callAccepted": {
                        setCallAccepted(true);
                        if (peerRef.current) {
                            peerRef.current.signal(data.signal);
                        }
                        setIsInCall(true);
                        break;
                    }
                    case "endCall": {
                        endCall(); // End call if received "endCall" message
                        break;
                    }
                    case "error": {
                        console.log(data.detail);
                        setShowLoginModal(true);
                        break;
                    }
                    default:
                        console.error(`Unknown message type: ${data.type}`);
                }
            };

            navigator.mediaDevices
                .getUserMedia({ video: true, audio: true })
                .then(stream => {
                    setStream(stream);
                })
                .catch(error => {
                    console.error("Error accessing media devices.", error);
                });

            return () => {
                if (socket.current) {
                    socket.current.close();
                }
                if (peerRef.current) {
                    peerRef.current.destroy();
                }
            };
        }
    }, [user]);

    useEffect(() => {
        if (flag) {
            setUsersFlag(false);
        }
    }, [flag]);

    useEffect(() => {
        if (stream && userVideo.current) {
            userVideo.current.srcObject = stream;
        }
    }, [stream]);

    function callPeer(id) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            config: {
                iceServers: [
                    {
                        urls: "stun:stun.relay.metered.ca:80",
                        username: config.TURN_USER,
                        credential: config.TURN_PASSWORD,
                    },
                    {
                        urls: "turn:global.relay.metered.ca:80",
                        username: config.TURN_USER,
                        credential: config.TURN_PASSWORD,
                    },
                    {
                        urls: "turn:global.relay.metered.ca:80?transport=tcp",
                        username: config.TURN_USER,
                        credential: config.TURN_PASSWORD,
                    },
                    {
                        urls: "turn:global.relay.metered.ca:443",
                        username: config.TURN_USER,
                        credential: config.TURN_PASSWORD,
                    },
                    {
                        urls: "turns:global.relay.metered.ca:443?transport=tcp",
                        username: config.TURN_USER,
                        credential: config.TURN_PASSWORD,
                    },
                ],
            },
            stream: stream,
        });

        peer.on("signal", data => {
            socket.current.send(
                JSON.stringify({
                    type: "callUser",
                    userToCall: id,
                    signalData: data,
                    from: yourID,
                }),
            );
        });

        peer.on("stream", stream => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        peerRef.current = peer; // Сохраняем peer в ref
        setIsInCall(true);
    }

    function acceptCall() {
        setCallAccepted(true);
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });

        peer.on("signal", data => {
            socket.current.send(
                JSON.stringify({
                    type: "acceptCall",
                    signal: data,
                    to: caller,
                }),
            );
        });

        peer.on("stream", stream => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        peer.signal(callerSignal);
        peerRef.current = peer; // Сохраняем peer в ref
        setIsInCall(true);
    }

    function endCall() {
        setCallAccepted(false);

        if (peerRef.current && socket.current.readyState === WebSocket.OPEN) {
            socket.current.send(
                JSON.stringify({
                    type: "endCall",
                    to: receivingCall ? yourID : caller, // Send to the other peer
                }),
            );
        }

        if (partnerVideo.current) {
            partnerVideo.current.srcObject = null;
        }
        if (peerRef.current) {
            peerRef.current.destroy();
        }
        setReceivingCall(false);
        setCaller("");
        setCallerSignal(null);
        setIsInCall(false);
    }

    useEffect(() => {
        const handleBeforeUnload = event => {
            event.preventDefault();
            endCall();
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            endCall();
        };
    }, []);

    const toggleMuteSound = () => {
        const updatedMuteSoundState = !isMutedSound;
        setIsMutedSound(updatedMuteSoundState);
        if (stream) {
            const audioTracks = stream.getAudioTracks();
            audioTracks.forEach(track => {
                track.enabled = !updatedMuteSoundState;
            });
        }
    };

    const toggleMuteVideo = () => {
        const updatedMuteVideoState = !isMutedVideo;
        setIsMutedSVideo(updatedMuteVideoState);
        if (stream) {
            const videoTracks = stream.getVideoTracks();
            videoTracks.forEach(track => {
                track.enabled = !updatedMuteVideoState;
            });

            if (updatedMuteVideoState) {
                const videoElement = document.querySelector("video"); // или выберите элемент видео по селектору
                videoElement.poster = "placeholder.jpg"; // Путь к вашему постеру
            }
        }
    };

    const handleModalClose = () => {
        setShowLoginModal(false);
    };

    const handleLoginSuccess = () => {
        setShowLoginModal(false);
    };

    if (showLoading) {
        return (
            <div>
                <FMAvatarBeatFade bgColor={"red"} />
                <div>LOADING...</div>
            </div>
        );
    }

    let incomingCall;
    if (receivingCall) {
        incomingCall = (
            <div>
                <h2>
                    {caller} is {callAccepted ? "connected" : "calling you"}
                </h2>
                {!callAccepted && <button onClick={acceptCall}>Accept</button>}
            </div>
        );
    }

    return (
        <div className="video-chat-container">
            <div className="video-chat-row">
                <div className="video-chat-video-container">
                    {stream && (
                        <Video
                            ref={userVideo}
                            stream={stream}
                            isVideoEnabled={!isMutedVideo}
                            placeholderImage={placeholderImage}
                            className="video-chat-user-video"
                        />
                    )}
                </div>
                <div className="video-chat-video-container">
                    {callAccepted && (
                        <Video
                            ref={partnerVideo}
                            isVideoEnabled={true}
                            className="video-chat-partner-video"
                        />
                    )}
                </div>
            </div>
            <div className="video-chat-row-controls">
                <div className="video-chat-video-controls">
                    <VideoControls
                        isMutedSound={isMutedSound}
                        isMutedVideo={isMutedVideo}
                        toggleMuteSound={toggleMuteSound}
                        toggleMuteVideo={toggleMuteVideo}
                    />
                </div>
                <div className="video-chat-video-controls">
                    {callAccepted && (
                        <Button
                            type="primary"
                            onClick={endCall}
                            icon={
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    style={{ color: "black" }}
                                />
                            }
                            style={{ background: "red" }}
                        >
                            End
                        </Button>
                    )}
                </div>
            </div>
            <div className="video-chat-row">
                {!isInCall && (
                    <CallButton
                        users={users}
                        yourID={yourID}
                        callPeer={callPeer}
                    />
                )}
            </div>
            <div className="video-chat-row">
                {incomingCall && (
                    <IncomingCall
                        caller={caller}
                        callAccepted={callAccepted}
                        acceptCall={acceptCall}
                    />
                )}
            </div>
            <VideoLoginModal
                showLoginModal={showLoginModal}
                handleModalClose={handleModalClose}
                onLoginSuccess={handleLoginSuccess}
            />
        </div>
    );
}

export default VideoChat;
