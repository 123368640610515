// src/components/homePage/header/Header.js
import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import MenuAuth from "../../menu/menuAuth/MenuAuth";
import FMAvatarBig from "../../avatars/FMAvatarbig";

import "./Header.css";

function Header({ bgColor, onMouseEnter, onMouseLeave }) {
    const { isAuthenticated, user } = useAuth();
    const location = useLocation();
    const showLogout =
        location.pathname.includes("/trainer-dashboard") ||
        location.pathname.includes("/student-dashboard");

    return (
        <div className="header-container">
            {!isAuthenticated ? (
                <>
                    <MenuAuth />
                </>
            ) : !showLogout ? (
                <div
                    className="home-page-enter-avatar"
                    style={{ position: "absolute", top: 10, right: 10 }}
                >
                    <Link
                        to={
                            user.isTrainer
                                ? "/trainer-dashboard"
                                : "/student-dashboard"
                        }
                    >
                        <FMAvatarBig
                            bgColor={bgColor}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        />
                    </Link>
                </div>
            ) : (
                <MenuAuth />
            )}
        </div>
    );
}

export default Header;
