import React, { createContext, useState, useContext, useCallback } from "react";

const TrainersContext = createContext();

export const useTrainers = () => useContext(TrainersContext);

export const TrainersProvider = ({ children }) => {
    const [trainers, setTrainers] = useState({});
    // const user = useAuth();

    const loadTrainers = useCallback(async () => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
            const response = await fetch("/api/v11/schedule/search/all", {
                method: "POST",
                headers: {
                    // 'Authorization': `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ time_zone: timeZone }),
            });
            const data = await response.json();
            setTrainers(data);
        } catch (error) {
            console.error("Failed to load trainers", error);
        }
    }, [setTrainers]); // зависимости для useCallback

    return (
        <TrainersContext.Provider value={{ trainers, loadTrainers }}>
            {children}
        </TrainersContext.Provider>
    );
};
