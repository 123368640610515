// components/homePage/HomePage.js
import React, { useState, useRef } from "react";

import { useAuth } from "../../context/AuthContext";
import Header from "./header/Header";
import MainSection from "./mainSection/MainSection";
import CTA from "./callToAction/CTA";
import BenefitsSection from "./benefits/benefitsSection/BenefitsSection";

import "./HomePage.css";

function HomePage() {
    const { isAuthenticated, user } = useAuth();
    const [bgColor, setBgColor] = useState("#fff"); // Начальный цвет фона аватара
    const timerRef = useRef(null);

    const handleMouseEnter = () => {
        setBgColor("red");
        timerRef.current = setTimeout(() => {
            // Перемещение курсора на аватар
            const avatar = document.querySelector(".home-page-enter-avatar");
            if (avatar) {
                const avatarRect = avatar.getBoundingClientRect();
                const event = new MouseEvent("mousemove", {
                    bubbles: true,
                    clientX: avatarRect.left + avatarRect.width / 2,
                    clientY: avatarRect.top + avatarRect.height / 2,
                });
                document.dispatchEvent(event);
            }
        }, 2000); // 2 секунды
    };

    const handleMouseLeave = () => {
        setBgColor("#fff");
        clearTimeout(timerRef.current);
    };

    return (
        <>
            <div className="home-page-welcome">
                {isAuthenticated ? (
                    <div className="p">
                        Привет, {user.email}!<br />
                        Ты пришел к нам как{" "}
                        {user.isTrainer ? "Мастер" : "Падаван"}.<br />
                        <br />
                        <div
                            className="go-to-your-zone-text"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            Пройди в свою зону...
                        </div>
                    </div>
                ) : (
                    <p></p>
                )}
            </div>
            <div>
                <Header
                    bgColor={bgColor}
                    onMouseEnter={() => setBgColor("red")}
                    onMouseLeave={() => setBgColor("#fff")}
                />
                <MainSection />
                <CTA />
                <BenefitsSection />
            </div>
        </>
    );
}

export default HomePage;
