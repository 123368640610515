// src/components/homePage/benefits/benefitsSlide/BenefitSlide.js
import React from "react";
import PropTypes from "prop-types";

import "./BenefitSlide.css";

const BenefitSlide = ({ title, description }) => {
    return (
        <div className="benefit-slide">
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    );
};

BenefitSlide.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default BenefitSlide;
