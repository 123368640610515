import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useAuth } from "../../../../context/AuthContext";
import { useTrainer } from "../../../../context/TrainerContext";

import "./TrainerFoto.css";

function TrainerPhoto() {
    const [uploading, setUploading] = useState(false);
    const [photoUrl, setPhotoUrl] = useState(null);
    const { setTrainerData } = useTrainer();
    const { user } = useAuth();

    // Загрузка фото при инициализации
    useEffect(() => {
        async function fetchTrainerPhoto() {
            try {
                const response = await fetch(
                    `/api/v11/user/get_photo_by_token`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                    },
                );
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    setPhotoUrl(url);
                    // setTrainerData({ photo: url });  // Обновление контекста
                } else {
                    console.error("Photo not found or failed to fetch");
                }
            } catch (error) {
                console.error("Error fetching photo:", error);
            }
        }

        fetchTrainerPhoto();
    }, [user]);

    // Обработчик изменения файла для загрузки нового фото
    const handleFileChange = async event => {
        const file = event.target.files[0];
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append("photo", file);

            try {
                const response = await fetch(`/api/v11/user/upload_photo`, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                    },
                    body: formData,
                });

                if (response.ok) {
                    const { photoUrl } = await response.json();
                    setPhotoUrl(photoUrl);
                    setTrainerData({ photo: photoUrl });
                    toast.success("Photo uploaded successfully");
                } else {
                    throw new Error("Failed to upload photo");
                }
            } catch (error) {
                console.error("Error uploading photo:", error);
                toast.error("Error uploading photo");
            } finally {
                setUploading(false);
            }
        }
    };

    return (
        <div
            onClick={() =>
                document.getElementById("photo-upload-input").click()
            }
            style={{
                cursor: "pointer",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: photoUrl ? "" : "#ccc",
            }}
        >
            {photoUrl ? (
                <img
                    src={photoUrl}
                    alt="Trainer"
                    style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "50%",
                    }}
                />
            ) : (
                "No Photo"
            )}
            <input
                type="file"
                id="photo-upload-input"
                onChange={handleFileChange}
                style={{ display: "none" }}
                disabled={uploading}
            />
        </div>
    );
}

export default TrainerPhoto;
