// ./src/student/TimeSlot/CompletedTrainings/CompletedTrainings.js
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import { useTrainer } from "../../../../context/TrainerContext";

import "./CompletedTrainings.css";

function CompletedTrainings({ trainerId }) {
    const { trainers, setTrainerData } = useTrainer();
    const [completedTrainings, setCompletedTrainings] = useState(
        trainers[trainerId]?.completedTrainings,
    );

    useEffect(() => {
        if (completedTrainings === undefined && trainerId) {
            fetch(`/api/v11/booking/count_completed`, {
                method: "POST",
                headers: {
                    // 'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ trainer_id: trainerId }),
            })
                .then(response =>
                    response.ok
                        ? response.json()
                        : Promise.reject("Failed to fetch training count"),
                )
                .then(data => {
                    setCompletedTrainings(data);
                    setTrainerData(trainerId, {
                        ...trainers[trainerId],
                        completedTrainings: data,
                    });
                })
                .catch(error => {
                    console.error("Failed to load training count", error);
                    toast.error("Failed to load training count");
                });
        }
    }, [trainerId, completedTrainings, trainers, setTrainerData]);

    return (
        <div className="time-slot-info">
            {completedTrainings !== undefined ? (
                <div className="rating-content-container">
                    <FontAwesomeIcon
                        className="completed-trainings-icon"
                        icon={faTrophy}
                        title="Количество проведенных тренировок"
                    />
                    {completedTrainings}
                </div>
            ) : (
                "Loading..."
            )}
        </div>
    );
}

export default CompletedTrainings;
