// components/menu/menuDashboard/MenuDashboard.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Menu, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
import { MailOutlined } from "@ant-design/icons";
import { notification } from "antd";

import { useAuth } from "../../../context/AuthContext";
import CrosshairIcon from "../../../svg/crosshairIcon/MenuIcon";

import "./MenuDashboard.css";

const MenuDashboard = () => {
    const { logout, isAuthenticated, user } = useAuth();
    const navigate = useNavigate();
    const [drawerVisible, setDrawerVisible] = useState();

    const handleMenuClick = e => {
        switch (e.key) {
            case "homepage": {
                navigate("/");
                closeDrawer();
                break;
            }
            case "logout": {
                logout();
                navigate("/");
                closeDrawer();
                break;
            }
            case "confirm_email": {
                handleConfrimEmailItem();
                break;
            }
            default:
                break;
        }
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const handleConfrimEmailItem = async () => {
        try {
            const response = await fetch(
                "api/v11/user/verify_email/generate_url",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user.access_token}`,
                    },
                },
            );

            if (!response.ok) {
                throw new Error(
                    "Ошибка при отправке запроса на подтверждение email",
                );
            }

            //   const result = await response.json();
            notification.success({
                message: "Готово",
                description: (
                    <span>
                        Письмо с подтверждением отправлено! Проверь свою почту.
                        <br />
                        (Не забудь заглянуть в СПАМ)
                    </span>
                ),
                placement: "topRight",
            });
        } catch (error) {
            console.error("Ошибка при отправке запроса:", error);
            notification.error({
                message: "Оопс...",
                description:
                    error.message ||
                    "Что то пошло не так! Попробуй еще чуть позже",
                placement: "topRight",
            });
        }
    };

    const menuItems = [
        {
            label: "Главная",
            key: "homepage",
            icon: <HomeOutlined />,
        },
        {
            label: "Сбежать",
            key: "logout",
            icon: <LogoutOutlined />,
        },
    ];

    if (isAuthenticated && !user.is_email_confirmed) {
        menuItems.push({
            label: <span className="confirm-email">Подтверди E-mail</span>,
            key: "confirm_email",
            icon: <MailOutlined />,
        });
    }

    return (
        <>
            <div
                className="menu-dashboard"
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: "10px",
                }}
            >
                <Button
                    type="primary"
                    onClick={showDrawer}
                    icon={<CrosshairIcon />}
                />
                <Drawer
                    className="menu-dashboard-drawer"
                    title="Меню"
                    placement="right"
                    onClose={closeDrawer}
                    open={drawerVisible}
                    style={{ opacity: 0.9 }}
                >
                    <Menu
                        onClick={handleMenuClick}
                        items={menuItems} // Используем свойство items для определения элементов меню
                    />
                    {isAuthenticated && user.is_email_confirmed && (
                        <div
                            style={{
                                marginTop: "20px",
                                color: "green",
                                textAlign: "center",
                            }}
                        >
                            E-mail подтвержден
                        </div>
                    )}
                </Drawer>
            </div>
        </>
    );
};

export default MenuDashboard;
