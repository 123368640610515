// src/components/mainSection/MainSection.js
import React from "react";

import "./MainSection.css";

function MainSection() {
    return (
        <div className="main-section-container">
            <h1>Fragmentor - твой персональный тренер</h1>
            <p>Перейди на новый уровень с профессиональным тренером</p>
            <h2>Индивидуальные тренировки в онлайн играх</h2>
        </div>
    );
}

export default MainSection;
