// src/components/homePage/benefits/benefitsSection/BenefitsSection.js
import React from "react";
import { Carousel } from "antd";

import BenefitSlide from "../benefitSlide/BenefitSlide";

import "./BenefitsSeciton.css";

const BenefitsSection = () => {
    const benefits = [
        {
            title: "Быстрое устранение ошибок в игре",
            description:
                "Наши тренеры помогут вам быстро выявить и исправить ошибки в вашей игре.",
        },
        {
            title: "Опытные тренеры",
            description:
                "В нашей команде только опытные тренеры с многолетним стажем.",
        },
        {
            title: "Гибкий график тренировок",
            description:
                "Мы предлагаем гибкий график тренировок, чтобы вы могли заниматься в удобное для вас время.",
        },
        {
            title: "Персонализированные планы тренировок",
            description:
                "Мы разрабатываем индивидуальные планы тренировок, чтобы удовлетворить уникальные потребности каждого игрока.",
        },
        {
            title: "Анализ игровых данных",
            description:
                "Наши инструменты анализа помогут вам понять свои сильные и слабые стороны, чтобы улучшить вашу игру.",
        },
        {
            title: "Сообщество единомышленников",
            description:
                "Присоединяйтесь к сообществу игроков, которые также стремятся улучшить свои навыки и делиться опытом.",
        },
        {
            title: "Поддержка 24/7",
            description:
                "Наша служба поддержки готова помочь вам в любое время, чтобы обеспечить наилучший опыт тренировок.",
        },
    ];

    return (
        <div className="benefits-section">
            <h1>Преимущества нашей платформы</h1>
            <Carousel autoplay dots={true} rtl>
                {benefits.map((benefit, index) => (
                    <BenefitSlide
                        key={index}
                        title={benefit.title}
                        description={benefit.description}
                    />
                ))}
            </Carousel>
        </div>
    );
};

export default BenefitsSection;
