import React, { useState } from "react";
import { Input, Button, message } from "antd";
import axios from "axios";
import { SaveOutlined, RedoOutlined, UndoOutlined } from "@ant-design/icons";

import { useAuth } from "../../../../context/AuthContext";

import "./TrainerAliasInput.css";

const TrainerAliasInput = () => {
    const { user, updateUser } = useAuth();
    const [nickname, setAlias] = useState(user.nickname);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleAliasChange = e => {
        setAlias(e.target.value);
    };

    const handleAliasSave = async () => {
        setLoading(true);
        try {
            const response = await axios.patch(
                "/api/v11/user/update_by_token",
                { nickname },
                {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                    },
                },
            );
            if (response.status === 200) {
                message.success("Nickname добавлен");
                setEditing(false);

                // Update user.nickname in localStorage and context
                const updatedUser = { ...user, nickname };
                updateUser(updatedUser); // Используем функцию updateUser для обновления состояния контекста и localStorage
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                message.error("Этот никнейм уже занят");
            } else {
                message.error("Не удалось обновить псевдоним");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleCancelClick = () => {
        setAlias(user.nickname);
        setEditing(false);
    };

    return (
        <div className="trainer-alias-container">
            {editing || !user.nickname ? (
                <>
                    <Input
                        className="trainer-alias-input-input"
                        value={nickname}
                        onChange={handleAliasChange}
                        placeholder="Введи псевдоним"
                    />
                    <Button
                        type="primary"
                        onClick={handleAliasSave}
                        loading={loading}
                        icon={<SaveOutlined />}
                        className="trainer-alias-input-btn"
                        title="Сохранить"
                    />
                    {user.nickname && (
                        <Button
                            type="primary"
                            onClick={handleCancelClick}
                            icon={<UndoOutlined />}
                            className="trainer-alias-input-btn"
                            title="Отменить"
                        />
                    )}
                </>
            ) : (
                <>
                    <span className="trainer-alias-display">
                        {user.nickname}
                    </span>
                    <Button
                        type="primary"
                        onClick={handleEditClick}
                        icon={<RedoOutlined />}
                        className="trainer-alias-input-btn"
                        title="Поменять"
                    />
                </>
            )}
        </div>
    );
};

export default TrainerAliasInput;
