import React from "react";
import { useNavigate } from "react-router-dom";
import "./CTA.css";

function CTA() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/student-dashboard");
    };

    return (
        <div className="cta-main-container">
            <div className="cta-wrap">
                <button className="cta-button" onClick={handleClick}>
                    подобрать тренировку
                </button>
            </div>
        </div>
    );
}

export default CTA;
