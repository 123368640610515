import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Form, Input, Button, notification } from "antd";
import { LockOutlined, UserOutlined, GoogleOutlined } from "@ant-design/icons";

import YandexOutlined from "../../../svg/yandexIcon/YandexIcon";
import { useAuth } from "../../../context/AuthContext";

import "./login-form.css";

function LoginPage({ navigateTo = "/", onLoginSuccess }) {
    const [form] = Form.useForm();
    const { login, refreshReCaptcha, setRefreshReCaptcha } = useAuth();
    const navigate = useNavigate();
    const [re_captcha_token, setToken] = useState("");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const onFinish = async values => {
        try {
            const user = await login(
                values.email,
                values.password,
                re_captcha_token,
            );
            console.log("Login successful:", user);
            navigate(navigateTo);
            if (onLoginSuccess) {
                onLoginSuccess(); // вызываем onLoginSuccess после успешной авторизации
            }
        } catch (error) {
            console.error("Login failed:", error);
            notification.error({
                message: "Login Failed",
                description: error.message || "Login failed, please try again.",
                placement: "topRight",
            });
            setRefreshReCaptcha(!refreshReCaptcha);
        }
    };

    const setTokenFunc = getToken => {
        setToken(getToken);
    };

    const handleGoogleLogin = () => {
        window.location.href = `https://fragmentor.website/api/v11/google/login?timezone=${encodeURIComponent(
            timeZone,
        )}`;
    };

    const handleYandexLogin = () => {
        window.location.href = `https://fragmentor.website/api/v11/yandex/login?timezone=${encodeURIComponent(
            timeZone,
        )}`;
        // window.location.href = `http://localhost:8080/api/v11/yandex/login?timezone=${encodeURIComponent(
        //     timeZone,
        // )}`;
    };

    return (
        <div>
            <Form
                form={form}
                name="login_form"
                onFinish={onFinish}
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                layout="vertical"
                // size="small"
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Введи свой email!" }]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Введи свой пароль!" }]}
                >
                    <Input
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Пароль"
                    />
                </Form.Item>
                <Form.Item>
                    <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        onVerify={setTokenFunc}
                        action="LOGIN"
                        refreshReCaptcha={refreshReCaptcha}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Войти
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="default"
                        icon={<GoogleOutlined />}
                        block
                        onClick={handleGoogleLogin}
                    >
                        Войти с Google
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="default"
                        icon={<YandexOutlined />}
                        block
                        onClick={handleYandexLogin}
                    >
                        Войти с Yandex
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default LoginPage;
