import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./configs/config";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey={config.RE_CAPTCHA_KEY}>
            <App />
        </GoogleReCaptchaProvider>
    </React.StrictMode>,
);

reportWebVitals();
