// import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";

// import { useAuth } from "../../context/AuthContext";
// import { formatDate } from "../../utils/FormatDate";

// function BookingList() {
//     const { user } = useAuth();
//     const [bookings, setBookings] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [error, setError] = useState("");

//     useEffect(() => {
//         if (!user || !user.access_token) return;

//         const fetchBookings = async () => {
//             setIsLoading(true);
//             try {
//                 const response = await fetch("/api/v11/booking/list", {
//                     method: "POST",
//                     headers: {
//                         Authorization: `Bearer ${user.access_token}`,
//                         "Content-Type": "application/json",
//                     },
//                 });
//                 if (!response.ok) {
//                     throw new Error("Failed to load bookings");
//                 }
//                 const data = await response.json();
//                 setBookings(data);
//                 setIsLoading(false);
//                 // setupWebSocket();
//             } catch (error) {
//                 console.error("Error loading bookings:", error);
//                 setError("Failed to load bookings");
//                 setIsLoading(false);
//                 toast.error("Failed to load bookings!");
//             }
//         };

//         fetchBookings();

//         //     const setupWebSocket = () => {
//         //         const ws = new WebSocket(`ws://127.0.0.1:81/api/v11/ws/${generateRandomString(5)}?token=${encodeURIComponent(user.access_token)}`);

//         //         ws.onopen = () => {
//         //             ws.send(JSON.stringify({'type': 'init'}));
//         //         };

//         //         ws.onmessage = (event) => {
//         //             const socketData = JSON.parse(event.data);
//         //             console.log(socketData);
//         //             if (socketData.type === 'update_status') {
//         //                 updateBookingStatus(socketData.booking_id, socketData.booking_status);
//         //             }
//         //         };

//         //         ws.onerror = (error) => {
//         //             console.error('WebSocket error:', error);
//         //             toast.error("WebSocket connection error.");
//         //         };

//         //         ws.onclose = () => console.log('WebSocket disconnected');

//         //         return () => {
//         //             ws.close(); // Clean up WebSocket connection
//         //         };
//         //     };
//     }, [user]); // Depend on the user's access token

//     // const updateBookingStatus = (bookingId, status) => {

//     //     setBookings(currentBookings =>
//     //         currentBookings.map(booking =>
//     //             booking.id === bookingId ? { ...booking, status } : booking
//     //         )
//     //     );
//     // };

//     const getStatusIcon = status => {
//         switch (status) {
//             case "pending":
//                 return "🕒"; // Pending icon
//             case "payed":
//                 return "💳"; // Paid icon
//             case "completed":
//                 return "✔️"; // Completed icon
//             case "canceled":
//                 return "❌"; // Canceled icon
//             default:
//                 return "❓"; // Unknown status icon
//         }
//     };

//     return (
//         <div>
//             <h2>Your Bookings</h2>
//             {isLoading ? (
//                 <p>Loading bookings...</p>
//             ) : error ? (
//                 <p>{error}</p>
//             ) : bookings.length > 0 ? (
//                 <ul>
//                     {bookings.map((booking, index) => (
//                         <li key={index}>
//                             Booking ID: {booking.id}, Time:{" "}
//                             {formatDate(booking.time)}
//                             <span style={{ marginLeft: "10px" }}>
//                                 {getStatusIcon(booking.status)}
//                             </span>
//                         </li>
//                     ))}
//                 </ul>
//             ) : (
//                 <p>No bookings yet.</p>
//             )}
//         </div>
//     );
// }

// export default BookingList;
import React, { useEffect, useState } from "react";
import { List, Steps, message as toast } from "antd";
import { useMediaQuery } from "@react-hook/media-query";

import { useAuth } from "../../../context/AuthContext";
import { formatDate } from "../../../utils/FormatDate";

import "./BookingList.css";

function BookingList() {
    const { user } = useAuth();
    const [bookings, setBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        if (!user || !user.access_token) return;

        const fetchBookings = async () => {
            setIsLoading(true);
            try {
                const response = await fetch("/api/v11/booking/list", {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error("Failed to load bookings");
                }
                const data = await response.json();
                setBookings(data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error loading bookings:", error);
                setError("Failed to load bookings");
                setIsLoading(false);
                toast.error("Failed to load bookings!");
            }
        };

        fetchBookings();
    }, [user]);

    const getCurrentStep = status => {
        switch (status) {
            case "pending":
                return 0;
            case "payed":
                return 1;
            case "completed":
                return 2;
            case "canceled":
                return 3;
            default:
                return 0;
        }
    };

    const steps = [
        {
            title: "Обрабатывается",
            // description: "В обработке",
            icon: "🕒",
        },
        {
            title: "Оплачен",
            // description: "Оплата прошла успешно",
            icon: "💳",
        },
        {
            title: "Завершен",
            // description: "Дело сделано успешно",
            icon: "✔️",
        },
        {
            title: "Отменен",
            // description: "Заказ был отменен",
            icon: "❌",
        },
    ];

    const renderProgressDot = (iconDot, { index }) => (
        <span>{steps[index].icon}</span>
    );

    return (
        <div className="bookinglist-container">
            <h2>Текущие брони</h2>
            {isLoading ? (
                <p>Loading bookings...</p>
            ) : error ? (
                <p>{error}</p>
            ) : bookings.length > 0 ? (
                <List
                    itemLayout="horizontal"
                    dataSource={bookings}
                    renderItem={booking => (
                        <List.Item>
                            <div className="bookinglist-item">
                                <List.Item.Meta
                                    title={
                                        <div className="bookinglist-booking-title">
                                            Бронь ID: {booking.id}
                                            <span className="bookinglist-booking-time">
                                                {formatDate(booking.time)}
                                            </span>
                                        </div>
                                    }
                                    // description={`${formatDate(booking.time)}`}
                                />
                                <Steps
                                    current={getCurrentStep(booking.status)}
                                    size="small"
                                    direction={
                                        isMobile ? "horizontal" : "horizontal"
                                    }
                                    progressDot={renderProgressDot}
                                    className="custom-steps"
                                    // type={isMobile ? "inline" : null}
                                >
                                    {steps.map((step, index) => (
                                        <Steps.Step
                                            key={index}
                                            title={
                                                <span className="step-title">
                                                    {step.title}
                                                </span>
                                            }
                                            description={
                                                <span className="step-description">
                                                    {step.description}
                                                </span>
                                            }
                                        />
                                    ))}
                                </Steps>
                            </div>
                        </List.Item>
                    )}
                />
            ) : (
                <p>No bookings yet.</p>
            )}
        </div>
    );
}

export default BookingList;
