/**
 * Форматирует дату и время из объекта Date в строку формата 'YYYY-MM-DD HH:mm' в локальном времени.
 * @param {string} dateStr - Строка с датой и временем, которую нужно преобразовать.
 * @returns {string} Строка с датой и временем в формате 'YYYY-MM-DD HH:mm'.
 */
export const formatDate = dateStr => {
    let d = new Date(dateStr);
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Месяцы начинаются с 0
    let day = d.getDate().toString().padStart(2, "0");
    let hour = d.getHours().toString().padStart(2, "0");
    let minute = d.getMinutes().toString().padStart(2, "0");

    return `${hour}:${minute} ${day}-${month}-${year}`;
};
