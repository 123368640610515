import React, { useEffect } from "react";
import { List, Button, message } from "antd";

import { useAuth } from "../../../../context/AuthContext";
import { useSchedule } from "../../../../context/ScheduleContext";

function TrainerSchedule() {
    const { timeslots, fetchTimeSlots } = useSchedule();
    const { user } = useAuth();

    useEffect(() => {
        fetchTimeSlots();
    }, [fetchTimeSlots]);

    const handleDelete = async recordId => {
        try {
            const response = await fetch(`/api/v11/schedule/delete`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ record_id: recordId }),
            });
            if (!response.ok) {
                throw new Error("Failed to delete time slot");
            }
            fetchTimeSlots();
            message.success("Time slot deleted successfully");
        } catch (error) {
            console.error("Error deleting time slot:", error);
            message.error("Error deleting time slot");
        }
    };

    return (
        <div>
            {timeslots.length > 0 ? (
                <List
                    itemLayout="horizontal"
                    dataSource={timeslots}
                    renderItem={slot => (
                        <List.Item
                            key={slot.id}
                            actions={[
                                <Button
                                    key={slot.id}
                                    type="link"
                                    danger //github.com/jsx-eslint/eslint-plugin-react/tree/master/docs/rules/jsx-key.md                                    type="link"
                                    onClick={() => handleDelete(slot.id)}
                                >
                                    Удалить
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                // title={`Time Slot`}
                                description={slot.time}
                            />
                        </List.Item>
                    )}
                />
            ) : (
                <p>You have no scheduled time slots. Please add a time slot.</p>
            )}
        </div>
    );
}

export default TrainerSchedule;
