// src/utils/bookingUtils.js
import { toast } from "react-toastify";

export async function handleBooking(userToken, scheduleId) {
    try {
        const response = await fetch("/api/v11/booking/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`, // Используем токен пользователя
            },
            body: JSON.stringify({ schedule_id: scheduleId }),
        });
        if (!response.ok) {
            const errorData = await response.json(); // Получаем JSON с ошибкой, если что-то пошло не так
            throw new Error(
                errorData.message || "Failed to book the time slot",
            );
        }
        const result = await response.json();
        toast.success(result.message); // Показываем сообщение об успешном бронировании через toast
    } catch (error) {
        console.error("Error booking time slot:", error);
        toast.error("Error booking time slot"); // Показываем сообщение об ошибке через toast
    }
}
