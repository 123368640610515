import React, { useEffect, useState } from "react";

import { useTrainer } from "../../../../context/TrainerContext";
import defaultAvatar from "../../../../svg/fm-180.svg";

import "./TrainerFoto.css";

function TrainerPhoto({ trainerId }) {
    const { trainers, setTrainerData } = useTrainer();
    const [photo, setPhoto] = useState(trainers[trainerId]?.photo);

    useEffect(() => {
        if (!photo && trainerId) {
            fetch(`/api/v11/user/get_photo`, {
                method: "POST",
                headers: {
                    // 'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ trainer_id: trainerId }),
            })
                .then(response => {
                    if (response.ok && response.status !== 204) {
                        return response.blob();
                    } else {
                        throw new Error("No content");
                    }
                })
                .then(blob => {
                    const photoUrl = URL.createObjectURL(blob);
                    setPhoto(photoUrl);
                    if (trainers[trainerId]?.photo !== photoUrl) {
                        setTrainerData(trainerId, {
                            ...trainers[trainerId],
                            photo: photoUrl,
                        });
                    }
                })
                .catch(() => {
                    setPhoto(defaultAvatar);
                });
        }
    }, [trainerId, photo, trainers, setTrainerData]);

    return (
        <div className="trainer-photo-container">
            {photo ? (
                <img src={photo} alt="Trainer" className="trainer-photo" />
            ) : (
                <div className="no-photo">No photo</div>
            )}
        </div>
    );
}

export default TrainerPhoto;
