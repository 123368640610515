// LoginModal.js
import React from "react";
import { Modal } from "antd";

import LoginForm from "../../auth/loginForm/LoginForm";

const VideoLoginModal = ({
    showLoginModal,
    handleModalClose,
    onLoginSuccess,
}) => (
    <Modal
        title="Ошибка авторизации"
        open={showLoginModal}
        onCancel={handleModalClose}
        footer={null}
    >
        <LoginForm navigateTo="/video-chat" onLoginSuccess={onLoginSuccess} />
    </Modal>
);

export default VideoLoginModal;
